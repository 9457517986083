
@import "../global/variables";
@import "../global/fonts";
@import "../global/colors";
@import "../mixins/util";
@import "../mixins/font";
@import "../global//spacers";
@import '../variables';
@import "bootstrap/scss/mixins/breakpoints";

#discount-popup-container,
#discount-popup-nb-reward-container {
    z-index: 101;
    box-shadow: 0 3px 5px $boulder;
    border-top: 1px solid $white-smoke;

    .column__separator {
        width: rem(80);
        border: 2px solid $nb-red;
        background: $nb-red;
    }

    .tnc-text > a {
        text-decoration: underline;
    }
}

#discount-popup-nb-reward-container {
    &.v2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        resize: vertical;
        overflow: auto;
        width: 100%;
        height: 100%;
        z-index: 999;
        position: fixed;
        top: 0;
        left: 0;
        padding: 0;
    }

    .background {
        background-color: #000;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .interior {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        background: white;
        position: relative;
        padding: 20% 0;
        @include media-breakpoint-up(lg) {
            width: 800px;
            height: auto;
            padding: 0;
        }
    }
}
